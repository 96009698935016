import styles from "./HomeEmpty.module.css";

const HomeEmptyRu: React.FC = (props) => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h1>{`Регламент сайта и приложения`}</h1>
        <div>
          {`Использование веб-сайта или приложения «Железные дороги Израиля» (далее вместе — «Веб-сайт») и содержимого, представленного на нем, а также любыми другими способами регулируется условиями использования, определенными и подробно описанными ниже. согласие со всеми условиями использования, подробно описанными в настоящих правилах.
         Использование приведенной ниже формы мужского рода предназначено только для удобства.`}
        </div>
        <h3>{"Интеллектуальная собственность"}</h3>
        <div>
          {`Все права интеллектуальной собственности и авторские права на данные и/или информацию, представленные на веб-сайте, принадлежат исключительно компании Israel Railways Ltd. (далее: «Железная дорога»). Вы не имеете права копировать, воспроизводить, продавать, переводить или публиковать каким-либо образом любую информацию. появляются на веб-сайте, и вы не имеете права использовать какие-либо детали веб-сайта в коммерческих целях без предварительного письменного разрешения железной дороги.

Использование веб-сайта и его информации будет осуществляться только для личных и некоммерческих нужд и в соответствии с законом.

Права интеллектуальной собственности на сайт, включая авторские права, применимые к информации на сайте, его содержанию, графическому оформлению сайта и любой другой детали на нем, а также к товарному знаку сайта, включая слоган поезда и логотип поезда, являются собственностью только поезда. Их нельзя использовать, а также распространять, копировать, публично демонстрировать или передавать какой-либо части содержания сайта третьим лицам без предварительного письменного согласия поезда.

`}
        </div>
        <h3>{`Содержание сайта и его целостность`}</h3>
        <div>
          {`Информация на сайте представлена ​​исключительно для удобства пользователя. Железная дорога и/или кто-либо от ее имени не будет нести никакой ответственности за любой ущерб, неудобства или горе, а также за любой прямой и/или косвенный результат, который будет причинен пользователю, его имуществу или третьему лицу в результате использования содержание на веб-сайте или из-за того, что вы полагаетесь на него. Израильские железные дороги и/или кто-либо от ее имени не будут нести ответственность и не будут нести какой-либо косвенный ущерб, его результаты и ущерб, причиненный пользователю или третьему лицу в результате использования веб-сайта и/или информации на нем и/ или предоставляемые там услуги – какой бы ни была причина претензии.

Поезд не несет ответственности за любую ошибку, ошибку, упущение или неточность и/или обновление информации и/или данных, опубликованных на сайте, включая изменение времени в пути и/или маршрутов, несоблюдение расписания и/или отмену. поездов, а также за любой ущерб и/или расходы, понесенные в результате.
Да, железная дорога не несет никакой ответственности за контент, появляющийся на других веб-сайтах, на которые ведут ссылки на веб-сайте, и не умаляя этого правила, в том числе на веб-сайтах по продаже железнодорожных билетов, и не гарантирует, что любая ссылка приведет на активный сайт.

Израильские железные дороги прилагают все усилия для поддержания нормальной работы участка. Однако Железные дороги Израиля не гарантируют, что услуга на сайте будет предоставляться без перебоев и сбоев, в том числе сбоев в оборудовании, программном обеспечении или линиях связи. Израильские железные дороги не несут прямой и/или косвенной ответственности за любой ущерб в случае возникновения технической проблемы, и/или сбоев в работе сайта и/или сбоев в работе веб-сайта и предоставляемых на нем услуг, и/или сбоев и сбоев в работе глобальной сети Интернет. и/или различные линии связи, обеспечивающие доступ в Интернет.

Израильские железные дороги прилагают все усилия для обеспечения безопасности использования веб-сайта и отсутствия каких-либо «вирусов» или любого вредоносного и/или незаконного программного обеспечения. Однако Железные дороги Израиля не могут гарантировать, что веб-сайт будет безопасен в использовании и не будет содержать вредоносных и/или нелегальных программ в любое время. Израильские железные дороги не несут прямой и/или косвенной ответственности за любой ущерб, причиненный пользователю в результате «вирусов» и/или вредоносного или нелегального программного обеспечения.
`}
        </div>
        <h3>{`Рекламный контент`}</h3>
        <div>
          {`Рекламный контент, поскольку он публикуется на веб-сайте или на веб-сайтах, на которые ведут ссылки на веб-сайте, является исключительной ответственностью рекламодателей. Пользователь несет исключительную ответственность за использование рекламного контента и доверие к нему.

`}
        </div>
        <h3>{`Допустимое использование`}</h3>
        <div>
          {`Использование веб-сайта и/или приложения будет разумным и приемлемым для законных целей в соответствии с условиями и инструкциями настоящих правил и с учетом соответствующих правовых положений.
Категорически запрещается совершать действия, которые могут вызвать большую и необоснованную нагрузку на сайт или инфраструктуру, повредить программный код или видимость сайта или получить к нему доступ незаконными способами.
`}
        </div>
        <h3>{`Сервис получения обновлений об изменениях в приложении`}</h3>
        <div>
          {`Как и любой сервис отправки информации, сервис «Уведомить меня об изменениях» также может быть подвержен сбоям связи и другим неполадкам. Таким образом, Израильские железные дороги не несут ответственности за любой ущерб или расходы, понесенные пользователями услуги или любым другим лицом из-за того, что они полагались на обновления, которые не были получены вообще, которые были получены неверным или искаженным образом, которые содержали информацию. которые не были точными или актуальными на момент их получения или на момент их распространения, а также обновления, которые не распространялись вообще или распространялись ненадлежащим образом по какой-либо причине.
`}
        </div>
        <h3>{`Условия проезда на поезде`}</h3>
        <div>
          {`Перевозка пассажиров и их багажа в поездах регулируется правилами, установленными соответствующими правовыми положениями, в том числе Постановлением о железных дорогах [новая редакция] 1972 года и правилами, установленными в соответствии с ним, а также инструкциями Министерства Транспорт и дополнительные условия проезда, о которых железная дорога время от времени информирует общественность различными способами, в том числе на этом сайте.
    `}
        </div>
        <h3>{`Покупка проездных билетов`}</h3>
        <div>
          {`Билеты можно приобрести через другие веб-сайты, которыми управляют коммерческие и другие организации, а не Израильские железные дороги. Израильские железные дороги не несут ответственности за информацию на этих сайтах или условия покупки на них билетов, даже если на этом сайте появляются соединения, ведущие на эти сайты.

Условия использования карты Rab-Co не входят в сферу ответственности Израильских железных дорог и публикуются на веб-сайтах Rab-Co онлайн или Hopon.
`}
        </div>
        <h3>{`Осуществление оплаты через сайт`}</h3>
        <div>
          {`Оплата через сайт удвоения туристических сборов, где с пассажира взимается плата, будет произведена путем предоставления личных данных (далее «идентификационные данные») пользователя, как это требуется на странице оплаты, а также данных действующей кредитной карты, признанной Израилем. Железные дороги по своему усмотрению.
Оплата будет снята с кредитной карты сразу после завершения процесса оплаты на веб-сайте и будет произведена одним платежом.
Условием завершения процесса оплаты является то, что введенные пользователем реквизиты были получены и усвоены на сайте в полном объеме, как того требует сайт.
Могут быть различные причины, которые могут вызвать сбои и сбои в процессе приема платежа, и пользователь заявляет, что он осознает возможность возникновения таких сбоев в его случае, не умаляя при этом своего права отменить платеж в случае неспособности завершить процесс оплаты.
Выполнение платежа будет завершено и будет считаться корректным только после получения подтверждения о завершении процесса оплаты.
Если во время или после совершения платежа пользователь получил сообщение об ошибке в платежных реквизитах или о сбое, произошедшем в системе при приеме платежного процесса, или если он не получил подтверждения о завершении платежного процесса, Пользователь должен связаться с центром обслуживания клиентов Железных дорог Израиля по телефону 5770* в соответствии с графиком работы центра и отрегулировать завершение процесса оплаты.
Ввод идентификационных данных, которые не принадлежат пользователю и/или не в силу законного разрешения использовать чужие идентификационные данные - полностью запрещен законом.
`}
        </div>
        <h3>{`Служба зарезервированных мест`}</h3>
        <div>{`Услуга неактивна до дальнейшего уведомления.


`}</div>
        <h3>{`Пилот «Прямая накопленная стоимость»`}</h3>
        <div>
          {`Согласно инструкциям Министерства транспорта, Израильские железные дороги реализуют услугу «прямой накопленной стоимости», которая будет опробована в пилотном периоде, начиная с 4 марта 2021 года.

Услуга «Прямая накопленная стоимость» позволяет многолинейным пассажирам, заявившим о накопленной стоимости, путешествовать поездом с использованием накопленной стоимости, проходя непосредственно через входные ворота вокзала (валидаторы) и выполняя валидацию без предварительной покупки. проездного билета и без необходимости выполнения операции Ранняя валидация.

Использование услуги «прямой накопленной стоимости» осуществляется путем подтверждения входа у въездных ворот (валидатора) на станции отправления с использованием многолинейной карты перед поездкой и подтверждения выхода у выездных ворот в пункте назначения. станция, в конце путешествия.

Использование услуги «Прямая накопленная стоимость» возможно при наличии денежного остатка в накопленной стоимости многолинейной карты, достаточного для оплаты полной стоимости проезда. Пассажир несет ответственность за обеспечение достаточного финансового баланса накопленной стоимости.

Обратите внимание: путешествие без достаточного финансового баланса приравнивается к поездке на поезде без подходящего билета!
  
Любое использование услуги «Прямая накопленная стоимость» для любой поездки зависит от минимального остатка накопленной стоимости в размере 30 шекелей.
  
Использование услуги «Прямая накопленная стоимость» разрешено только одному пассажиру.

Услуга «прямая накопленная стоимость» тестировалась в пилотном периоде в дополнение к обычной услуге.
Обычное использование накопленной стоимости продолжается без изменений, включая возможность конвертации одного билета или нескольких проездных билетов из накопленной стоимости и возможность выполнить раннюю проверку перед проверкой и прохождением через въездные ворота.

Обратите внимание: услуга «прямое накопление» не предназначена для пассажиров, имеющих на многолинейной карте как накопленную сумму, так и периодический проездной.

Согласно инструкциям Минтранса, как и при поездке на автобусе, при использовании многолинейки, имеющей как накопительную стоимость, так и периодический проездной, по умолчанию активируется периодический проездной. Если ваша многолинейная карта имеет и накопительную стоимость, и периодический проездной и вы желаете использовать накопленную сумму - подойдите к автоматам, активируйте накопленную сумму обычным способом, а не через услугу «прямое накопление».
`}
        </div>
        <h3>{`Использование платежных приложений`}</h3>
        <div>
          {`Использование платежных приложений осуществляется в соответствии с инструкциями Министерства транспорта и условиями использования приложения, с помощью которых пассажир решил оплатить проезд и выполнить валидацию на входных и выходных воротах на вокзалах. ООО «Израильские железные дороги» никоим образом не несет ответственности за правильность подачи заявок, сбор платы за проезд через них, уровень тарифов при их использовании, введение повышенных финансовых сборов через них или любой другой аспект их использования. информацию, пожалуйста, свяжитесь с веб-сайтом Национального управления общественного транспорта Министерства транспорта.

Согласно положениям закона и Министерства транспорта, поездка с использованием платежного приложения подлежит проверке на входе и выходе на вокзалах, аналогично проверке многоканальной карты. Проверка осуществляется путем сканирования штрих-кода, сгенерированного приложением и отображаемого на экране смартфона, у входных и выходных ворот на вокзалах. Сканирование штрих-кода правильно открывает входные или выходные ворота на вокзале, и только это позволяет пассажиру войти или выйти на станцию, в зависимости от обстоятельств.

Путешествие поездом без надлежащего подтверждения, как при входе на станцию ​​отправления, так и при выходе со станции назначения, приравнивается к поездке без билета и может повлечь за собой наложение повышенных финансовых сборов на пассажира через приложение. в соответствии с положениями закона и Министерства транспорта.

Пассажир должен предъявить по требованию служащего железной дороги экран приложения, на котором отображается штрих-код, предназначенный для конкретной поездки, и который, как уже упоминалось, должен быть подтвержден.

В случае каких-либо неполадок или жалоб, связанных с использованием платежных приложений, пассажир должен обратиться в сервисный центр Национального управления общественного транспорта Министерства транспорта по телефону: *8787 или в сервисный центр приложения. использовал.

Не обращайтесь по этим вопросам в сервисный центр Железных дорог Израиля, поскольку этот центр не может помочь в этих вопросах.

Пассажир, совершивший поездку с использованием платежного приложения, имеет право на компенсацию за задержку поезда, на тех же условиях, которые применяются к пассажиру, совершившему поездку с использованием бумажной карты или многолинейной карты, которые подробно описаны на данном сайте по адресу страница компенсации за задержку/отмену поезда
Компенсационная карта будет выдана пассажиру, имеющему право на компенсацию, посредством бумажной карты, SMS-сообщения или другого цифрового средства, определенного Израильскими железными дорогами.
`}
        </div>
        <h3>{`Конфиденциальность и конфиденциальность`}</h3>
        <div>
          {`Израильские железные дороги принимают необходимые меры для предотвращения вторжения на сайт, сохранения конфиденциальности личных данных пользователей и предотвращения возможного нарушения конфиденциальности пользователей. Однако нельзя полностью исключить сбои, и нельзя дать полную гарантию того, что конфиденциальность и конфиденциальность будут всегда сохраняться. Таким образом, пользователь настоящим заявляет, что он понимает содержание этого раздела и что у него не будет никаких претензий и/или требований и/или претензий к Израильским железным дорогам и/или кому-либо от ее имени из-за травм и/или неисправности и/ или сбои, которые приведут к возможному нарушению приватности и конфиденциальности предоставленной им информации пользователя.

Предоставление номера мобильного телефона при совершении операций на сайте означает согласие на получение служебных сообщений от Израильских железных дорог посредством текстовых сообщений, которые будут отправлены на указанный номер телефона.
`}
        </div>
        <h3>{`Изменения сайта и прекращение его деятельности`}</h3>
        <div>
          {`Израильские железные дороги оставляют за собой право по своему усмотрению изменять положения настоящих Правил, условия использования сайта, структуру сайта, его содержание, его внешний вид, а также объем и доступность предлагаемых услуг. в нем и любых других связанных с ним аспектах, без предварительного уведомления. Как правило, срок действия изменений будет со дня их публикации на сайте, если иное не установлено железной дорогой.
Железная дорога оставляет за собой право в любой момент по своему усмотрению прекратить деятельность сайта и пользователь не имеет к нему никаких претензий и/или претензий.

`}
        </div>
        <h3>{`место для осуждения`}</h3>
        <div>
          {`Законы Государства Израиль будут применяться только к этим правилам. Исключительным местом юрисдикции по всем вопросам, касающимся этого, является компетентный суд Лода.
`}
        </div>
      </div>
    </>
  );
};

export default HomeEmptyRu;
