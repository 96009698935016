import { useEffect } from "react";
import HomeEmptyHe from "./policyPage/HomeEmptyHe";
import i18next from "i18next";
import i18n from "./Services/i18n";
import HomeEmptyEn from "./policyPage/HomeEmptyEn";
import HomeEmptyAr from "./policyPage/HomeEmptyAr";
import HomeEmptyRu from "./policyPage/HomeEmptyRu";
import "./App.css";

const App = () => {
  useEffect(() => {
    document.documentElement.dir = i18next.dir(i18next.language);
  }, [i18n.language]);

  return (
    <div className="App">
      {i18n.language === "he" && <HomeEmptyHe />}
      {i18n.language === "en" && <HomeEmptyEn />}
      {i18n.language === "ar" && <HomeEmptyAr />}
      {i18n.language === "ru" && <HomeEmptyRu />}
    </div>
  );
};

export default App;
