import styles from "./HomeEmpty.module.css";

const HomeEmptyHe: React.FC = (props) => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h1>{` תקנון האתר והאפליקציה`}</h1>
        <div>
          {`השימוש באתר או באפליקציית רכבת ישראל (להלן יחד – "האתר") ובתכנים המוצגים בהם ובכל אמצעי אחר כפוף לתנאי השימוש המוגדרים ומפורטים להלן עצם השימוש באתר מעיד על הסכמת המשתמש לכל תנאי השימוש המפורטים בתקנון זה. 
        השימוש להלן בלשון זכר נעשה מטעמי נוחות בלבד.`}
        </div>
        <h3>{"קניין רוחני"}</h3>
        <div>
          {`כל זכויות הקניין הרוחני וזכויות היוצרים בנתונים ו/או המידע המופיעים באתר שייכות באופן בלעדי לרכבת ישראל בע"מ (להלן: "הרכבת"). אין להעתיק, לשכפל, למכור, לתרגם או לפרסם בכל דרך שהיא כל מידע המופיע באתר ואין לעשות כל שימוש מסחרי בכל פרט באתר ללא קבלת רשותה של הרכבת מראש ובכתב. 

השימוש באתר ובמידע שבו ייעשה רק לצרכים אישיים ולא מסחריים ובהתאם לדין.

זכויות הקניין הרוחני באתר, לרבות זכויות יוצרים, החלות על המידע שבאתר, תוכנו, עיצובו הגרפי של האתר וכל פרט אחר בו, וכן סימנו המסחרי של האתר ובכלל זה, הסיסמה של הרכבת ולוגו הרכבת הינם קניין הרכבת בלבד. אין לעשות בהם שימוש ואין להפיצם, להעתיקם, או להציגם בפומבי או למסור לגורם שלישי כלשהו כל חלק מתכולת האתר ללא הסכמת הרכבת מראש ובכתב.


`}
        </div>
        <h3>{`תוכן האתר ותקינותו`}</h3>
        <div>
          {`המידע באתר מוצג לנוחיות המשתמש בלבד. הרכבת ו/או מי מטעמה לא יישאו בכל אחריות לכל נזק, אי נוחות או עגמת נפש ולכל תוצאה ישירה ו/או עקיפה אשר ייגרמו למשתמש, לרכושו או לצד שלישי בשל השימוש בתכנים באתר או בשל ההסתמכות עליהם. רכבת ישראל ו/או מי מטעמה לא יהיו אחראים ולא יישאו בכל נזק עקיף, תוצאותיו ונזקיו שייגרמו למשתמש או לצד שלישי, כתוצאה משימוש באתר ו/או במידע שבו ו/או בשירותים הניתנים בו - תהא עילת התביעה אשר תהא. 

הרכבת לא תהא אחראית לכל טעות, שגיאה, השמטה או חוסר דיוק ו/או עדכון של המידע ו/או הנתונים המפורסמים באתר, ובכלל זה שינויים במועדי ו/או מסלולי הנסיעה, אי עמידה בלוחות זמנים ו/או ביטול רכבות, וכן לכל נזק ו/או הוצאה שיגרמו עקב כך.
כן לא תישא הרכבת באחריות כלשהי לתכנים המופיעים באתרים אחרים שאליהם מובילות קישוריות המצויות באתר, ומבלי לגרוע מכלל זה – לרבות אתרים למכירת כרטיסי נסיעה ברכבת, ואינה מתחייבת כי קישורית כלשהי תוביל לאתר אינטרנט פעיל. 

רכבת ישראל עושה את מירב המאמצים לשמור על תקינות פעילותו של האתר. עם זאת, רכבת ישראל אינה מתחייבת שהשירות באתר יינתן כסדרו בלא הפסקות ותקלות, לרבות תקלות בחומרה, בתוכנה, או בקווי התקשורת. רכבת ישראל לא תישא, במישרין ו/או בעקיפין, באחריות לנזק כלשהו במקרה של בעיה טכנית, ו/או הפסקות ו/או תקלות באתר ובשירות הניתן בו ו/או תקלות ושיבושים ברשת האינטרנט העולמית ו/או בקווי התקשורת השונים המאפשרים גישה לאינטרנט. 

רכבת ישראל עושה את מירב המאמצים על מנת להבטיח שהאתר יהיה בטוח לשימוש ונקי מכל 'וירוס' או כל תוכנה זדונית ו/או בלתי חוקית. עם זאת, אין ביכולתה של הרכבת ישראל להבטיח כי האתר יהיה בטוח לשימוש ונקי מתוכנות זדוניות ו/או בלתי חוקיות בכל זמן. רכבת ישראל לא תישא, במישרין ו/או בעקיפין, באחריות לנזק כלשהו שייגרם למשתמש כתוצאה מ'וירוסים' ו/או תוכנות זדוניות או בלתי חוקיות.

`}
        </div>
        <h3>{`תוכן פרסומי`}</h3>
        <div>
          {`תוכן פרסומי, ככל שהוא מתפרסם באתר או באתרים שאליהם מובילות קישוריות המצויות באתר הינו באחריות המפרסמים בלבד. השימוש בתוכן הפרסומי וההסתמכות עליו הם באחריות המשתמש בלבד.

 

`}
        </div>
        <h3>{`שימוש מקובל`}</h3>
        <div>
          {`השימוש באתר ו/או האפליקציה יהיה שימוש סביר ומקובל, למטרות חוקיות, ע"פ התנאים וההוראות בתקנון זה ובכפוף להוראות הדין הרלוואנטיות.
חל איסור מוחלט על ביצוע פעולות העלולות לגרום לעומס רב ובלתי סביר על האתר או התשתית, לפגום בקוד התוכנה או בנראות האתר או לגשת אליו באמצעים בלתי חוקיים.

`}
        </div>
        <h3>{`שירות קבלת עדכונים על שינויים באפליקציה`}</h3>
        <div>
          {`כמו כל שירות לשליחת מידע, גם שירות "עדכנו אותי על שינויים", עלול להיות מושפע מתקלות תקשורת ותקלות אחרות. על כן, רכבת ישראל איננה אחראית לכל נזק או הוצאה שייגרמו למשתמשי השירות, או כל אדם אחר בשל הסתמכותם על עדכונים שלא התקבלו כלל, שהתקבלו באופן שגוי או משובש, שהכילו מידע שאינו מדויק או עדכני לשעת קבלתם או לשעת הפצתם ועדכונים שלא הופצו כלל, או שהופצו באופן בלתי תקין, מסיבה כלשהי.

`}
        </div>
        <h3>{`תנאי הנסיעה ברכבת`}</h3>
        <div>
          {`הסעת הנוסעים וכבודתם ברכבות כפופה לכללים הקבועים בהוראות הדין הרלוואנטיות, ובהן פקודת מסילות הברזל [נוסח חדש], תשל"ב-1972 והתקנות שהותקנו מכוחה, וכן להוראות משרד התחבורה ולתנאי נסיעה נוספים עליהם מודיעה הרכבת לציבור מעת לעת, באמצעים שונים לרבות אתר זה. 
    
    `}
        </div>
        <h3>{`רכישת כרטיסי נסיעה`}</h3>
        <div>
          {`ניתן לרכוש כרטיסי נסיעה באמצעות אתרי אינטרנט אחרים המופעלים על ידי גורמים מסחריים ואחרים, ואינם מופעלים על ידי רכבת ישראל. רכבת ישראל אינה אחראית למידע באתרים אלו או לתנאי הרכישה של כרטיסי נסיעה בהם, אף אם קישוריות המובילות לאתרים אלו מופיעות באתר זה. 

תנאי השימוש בכרטיס רב-קו אינם באחריות רכבת ישראל והם מופיעים באתרים רב-קו אונליין או Hopon

`}
        </div>
        <h3>{`ביצוע תשלומים באמצעות האתר`}</h3>
        <div>
          {`תשלום באמצעות האתר של כפל דמי נסיעה שבו חויב נוסע, ייעשה באמצעות מתן פרטים אישיים (להלן "פרטי זיהוי") של המשתמש כנדרש בעמוד התשלום וכן פרטי כרטיס אשראי תקף המכובד ע"י רכבת ישראל, לפי שיקול דעתה הבלעדי. 
כרטיס האשראי יחויב בגין התשלום מיד בתום תהליך התשלום באתר וייעשה בחיוב אחד. 
תנאי להשלמת תהליך התשלום הינו שהפרטים שהזין המשתמש התקבלו ונקלטו באתר באופן מלא, כפי שנדרש באתר. 
ייתכנו סיבות שונות העלולות לגרום לתקלות ולשיבושים בקליטת תהליך התשלום והמשתמש מצהיר כי הוא מודע לאפשרות שתקלות כאמור יתרחשו בעניינו, מבלי לגרוע מזכותו לבטל את התשלום במקרה של כשל בהשלמת תהליך התשלום. 
ביצוע התשלום יושלם וייחשב כתקין רק לאחר קבלת אישור על השלמת תהליך התשלום.
אם המשתמש קיבל הודעה במהלך ביצוע התשלום או אחריו, בדבר שגיאה בפרטי התשלום או בדבר תקלה שארעה במערכת בעת קליטת תהליך התשלום או אם לא קיבל אישור על השלמת תהליך התשלום, על המשתמש לפנות אל מוקד שירות הלקוחות של רכבת ישראל בטלפון 5770* בהתאם לשעות פעילות המוקד, ולהסדיר את השלמת תהליך התשלום. 
הקלדת פרטי זיהוי שאינם של המשתמש ו/או שלא מכוח הרשאה חוקית לעשות שימוש בפרטי זיהוי של אחר - אסורה לחלוטין על פי דין.

`}
        </div>
        <h3>{`שירות מקום שמור`}</h3>
        <div>{`השירות אינו פעיל עד להודעה חדשה.


`}</div>
        <h3>{`פיילוט "ערך צבור ישיר"`}</h3>
        <div>
          {`על פי הוראות משרד התחבורה, מופעל ברכבת ישראל שירות "ערך צבור ישיר", שייבחן לתקופת פיילוט, החל מיום 4.3.2021.     

שירות "ערך צבור ישיר", מאפשר לנוסעים בעלי רב-קו, שטענו בו ערך צבור, לנסוע ברכבת באמצעות הערך הצבור, על ידי מעבר ישיר בשערי הכניסה של תחנת הרכבת (הוולידטורים) וביצוע תיקוף, ללא רכישה מוקדמת של כרטיס הנסיעה וללא צורך בביצוע פעולת ולידציה מוקדמת.  

השימוש בשירות "ערך צבור ישיר", נעשה על ידי תיקוף כניסה בשער הכניסה (הוולידטור) בתחנת המוצא, באמצעות כרטיס הרב-קו, לפני הנסיעה, ותיקוף יציאה בשער היציאה, בתחנת היעד, בסוף הנסיעה.

השימוש בשירות "ערך צבור ישיר", מותנה ביתרה כספית בערך הצבור בכרטיס הרב-קו, שתספיק לתשלום דמי הנסיעה המלאים לנסיעה. באחריות הנוסע לוודא שיש לו יתרה כספית מספיקה בערך הצבור.  

שימו לב: נסיעה ללא יתרה כספית מספיקה – כמוה כנסיעה ברכבת ללא כרטיס מתאים!       
  
כל שימוש בשירות "ערך צבור ישיר", לנסיעה כלשהי, מותנה ביתרה כספית מינימלית של 30 שקל בערך הצבור.       
  
השימוש בשירות "ערך צבור ישיר" מותר לנוסע אחד בלבד.   

שירות "ערך צבור ישיר" נבחן בתקופת הפיילוט, בנוסף לשירות הרגיל.  
השימוש הרגיל בערך צבור נמשך ללא שינוי, כולל האפשרות להמרת כרטיס אחד, או מספר כרטיסי נסיעה, מתוך הערך הצבור ואפשרות לבצע ולידציה מוקדמת, לפני התיקוף והמעבר בשער הכניסה.  

שימו לב: שירות "ערך צבור ישיר", אינו מיועד לנוסעים המחזיקים גם ערך צבור וגם כרטיס נסיעה תקופתי, בכרטיס הרב-קו שלהם.  

על פי הוראות משרד התחבורה, וכמו בנסיעה באוטובוס, שימוש ברב-קו שיש בו גם ערך צבור וגם כרטיס נסיעה תקופתי, מפעיל את כרטיס הנסיעה התקופתי כברירת מחדל. אם בכרטיס הרב-קו שלכם יש גם ערך צבור וגם כרטיס נסיעה תקופתי ואתם מעוניינים לעשות שימוש בערך הצבור – גשו למכונות המכירה האוטומטיות, הפעילו את הערך הצבור באופן רגיל ולא באמצעות שירות "ערך צבור ישיר". 

`}
        </div>
        <h3>{`שימוש באפליקציות תשלום `}</h3>
        <div>
          {`השימוש באפליקציות תשלום, הוא בהתאם להוראות משרד התחבורה ותנאי השימוש של האפליקציה שבאמצעותה בחר הנוסע לשלם את דמי הנסיעה ולבצע את התיקוף בשערי הכניסה והיציאה בתחנות הרכבת. רכבת ישראל בע"מ אינה אחראית באופן כלשהו לתקינות האפליקציות, לגביית דמי הנסיעה באמצעותן, לגובה דמי הנסיעה בעת השימוש בהן, להטלת חיובים כספיים מוגדלים באמצעותן, או לכל אספקט אחר של השימוש בהן. לפרטים נוספים יש לפנות לאתר הרשות הארצית לתחבורה ציבורית במשרד התחבורה

על פי הוראות הדין ומשרד התחבורה, נסיעה באמצעות אפליקציית תשלום, מותנית בביצוע תיקוף בשערי הכניסה והיציאה בתחנות הרכבת, בדומה לתיקוף כרטיס הרב-קו. התיקוף נעשה באמצעות סריקת ברקוד המופק על ידי האפליקציה ומוצג על גבי מסך הסמארטפון, בשערי הכניסה והיציאה בתחנות הרכבת. סריקת ברקוד באופן תקין פותחת את שער הכניסה או היציאה בתחנת הרכבת ורק היא מתירה לנוסע, להיכנס לתחנה או לצאת ממנה, לפי העניין.  

נסיעה ברכבת ללא תיקוף באופן תקין, הן בכניסה לתחנת המוצא והן ביציאה מתחנת היעד, כמוה כנסיעה ללא כרטיס ועלולה לגרום להטלת חיובים כספיים מוגדלים על הנוסע, באמצעות האפליקציה, בהתאם להוראות הדין ומשרד התחבורה.  

חובה על נוסע להציג, לפי דרישתו של פקיד רכבת, את מסך האפליקציה, שבו מוצג הברקוד שנועד לנסיעה המסוימת ושאותו יש לתקף, כאמור.  

בכל תקלה או תלונה הנוגעת לשימוש באפליקציות התשלום, על הנוסע לפנות למוקד השירות של הרשות הארצית לתחבורה ציבורית, במשרד התחבורה, בטלפון: 8787*, או למוקד השירות של האפליקציה שבה נעשה שימוש.  

אין לפנות בעניינים אלו למוקד השירות של רכבת ישראל, מכיוון שמוקד זה אינו יכול לסייע בעניינים אלו.  

נוסע שביצע נסיעה באמצעות אפליקציית תשלום, זכאי לפיצוי בגין איחור רכבת, לפי אותם התנאים החלים על נוסע שביצע את נסיעתו באמצעות כרטיס נייר או כרטיס רב-קו, המפורטים באתר זה בדף פיצוי בגין איחור/ביטול רכבת
כרטיס פיצויי יינתן לנוסע הזכאי לפיצוי באמצעות כרטיס נייר, הודעת SMS או אמצעי דיגיטלי אחר שנקבע על ידי רכבת ישראל.   

`}
        </div>
        <h3>{`סודיות ופרטיות`}</h3>
        <div>
          <b>{`כללי`}</b>
          <br />
          {`
רכבת ישראל בע״מ ("רכבת ישראל", "אנחנו" או "אנו") מחויבת להגן על פרטיות משתמשי האתר והאפליקציה ("משתמש/ים" או "אתם"). אתר האינטרנט של רכבת ישראל ("האתר") וכן האפליקציה של רכבת ישראל ("אפליקציה") מאפשרים למשתמשים לקבל מידע על אודות השירותים של רכבת ישראל ("השירותים"), להזמין נסיעות, להגיש מועמדות למשרה פנויה ולפנות לרכבת ישראל בנושאים שונים באמצעות טופסי פניה מקוונים ("שירותי האתר והאפליקציה")

מדיניות פרטיות זו ("מדיניות הפרטיות") נוצרה כדי ליידע אתכם לגבי הדרך שבה אנו אוספים, שומרים ומשתמשים במידע שאתם מספקים לנו באמצעות השימוש בשירותי האתר והאפליקציה, באמצעות כל מחשב או כל מכשיר אלקטרוני אחר, והיא מתארת את האמצעים שאנו נוקטים על מנת להגן על המידע הנאסף על ידנו. עצם השימוש באתר והורדת האפליקציה שלנו ו/או השימוש בה, מהווה הסכמה מצדכם למדיניות הפרטיות שלנו.
`}
          <br />
          <br />
          <b>
            {`

פירוט על איסוף המידע והשימוש בו
`}
          </b>
          <br />
          {`
בעת השימוש באתר ובאפליקציה שלנו, אנו עשויים לאסוף את סוגי המידע הבאים:

"מידע אישי" – מידע שניתן להשתמש בו על מנת ליצור עמכם קשר או לזהות אתכם. חלק מהמידע עשוי לזהות אתכם באופן אישי, כגון שמכם, כתובתכם, כתובת הדואר האלקטרוני שלכם, מספר הטלפון שלכם, מספר תעודת הזהות שלכם, וכיו"ב.

"מידע לא אישי" – מידע, שכשלעצמו איננו מאפשר לנו לזהות אתכם, כגון מידע סטטיסטי אודות העמודים באתר ובאפליקציה שבהם צפיתם, שירותי האתר והאפליקציה שעניינו אתכם ועוד.

אינכם חייבים למסור לנו את פרטי המידע האישי המתוארים להלן, אך לצורך השימוש בשירותי האתר והאפליקציה באופן המיטבי, ייתכן שתידרשו לשתף עם רכבת ישראל את פרטי המידע האישי המפורטים, אחרת, ייתכן שתוכלו לעשות שימוש בשירותי האתר והאפליקציה באופן חלקי בלבד.
  `}
          <br />
          <br />
          <b>
            {`

כיצד אנו אוספים את המידע שלכם?
`}
          </b>
          <br />
          {`
אנו אוספים מידע אישי ומידע לא אישי, בין היתר, באמצעות השיטות הבאות:
`}
          <br />
          <br />
          <b>{`טופס יצירת קשר:`}</b>
          <br />
          {`
 באפשרותכם ליצור עמנו קשר באמצעות טופס פניה מקוון, ואנו נאסוף את שמכם, כתובת המגורים, כתובת דואר אלקטרוני, מספר טלפון וכמובן את תוכן הפניה שלכם.

 `}
          <br />
          <b>{`איתור חפץ שאבד ברכבת:`}</b>
          <br />
          {`
 באפשרותכם ליצור עמנו קשר בנוגע לאבדה בתחנה או ברכבת, ואנו נאסוף את שמכם, מספר טלפון, תאריך ושעת האבדה ואת תוכן הפניה שלכם.
  `}
          <br />
          <b>{`בקשה לפיצוי בגין איחור/ביטול רכבת ישראל:`}</b>
          <br />
          {`
 באפשרותכם ליצור עמנו קשר בנוגע לפיצוי בגין איחור/ביטול רכבת ישראל, ואנו נאסוף את שמכם, כתובת המגורים, כתובת דואר אלקטרוני, מספר טלפון, פרטי הנסיעה ואת תוכן הפניה שלכם.
  `}
          <br />
          <b>{`בקשה לעריכת צילומי וידיאו במתחמי רכבת ישראל:`}</b>
          <br />
          {`
 באפשרותכם להגיש בקשה לעריכת צילומי וידיאו במתחמי רכבת ישראל, ואנו נאסוף את שמכם, מספר תעודת זהות, מספר טלפון, כתובת דואר אלקטרוני, ופירוט אודות הצילומים המבוקשים.

  `}
          <br />
          <b>{`קבלת מידע מרכבת ישראל:`}</b>
          <br />
          {`
 ייתכן שנעשה שימוש בכתובת הדואר האלקטרוני ו/או מספר הטלפון שלכם כדי לשלוח לכם עדכונים על שירותי רכבת ישראל.

 `}
          <br />
          <b>{`הגשת מועמדות למשרה פנויה:`}</b>
          <br />
          {`
 באפשרותכם להגיש מועמדות למשרה פנויה ברכבת ישראל באמצעות האתר. לשם כך, תידרשו להירשם למערכת שבאמצעותה נאסוף את שמכם, מספר תעודת זהות, מספר טלפון, כתובת דואר אלקטרוני, שם משתמש וסיסמה.
`}
          <br />
          <b>{`מידע נוסף:`}</b>
          <br />
          {`
 לצורך מתן שירותים וסיוע בפניות ושאלות מצד משתמשי האתר והאפליקציה ייתכן שנבקש מכם מידע נוסף.
`}
          <br />
          <b>
            {`
כיצד אנו משתמשים במידע שלכם?
  `}
          </b>
          <br />
          {`
אנו משתמשים במידע שנאסף מכם באופן הבא:

`}
          <br />
          <b>{`מתן השירותים:`}</b>
          <br />
          {`
 אנו עשויים להשתמש במידע אישי ובמידע לא אישי על מנת לספק את שירותי האתר והאפליקציה ועל מנת לנהל, לספק, לתמוך, לפתור בעיות טכניות, להגן ולשפר את שירותי האתר והאפליקציה, לוודא תפעול נאות שלהם וכן למטרות יצירת קשר אתכם.

 `}
          <br />
          <b>{`שליחת הודעות ויצירת קשר:`}</b>
          <br />
          {`
 בכפוף להוראות הדין, אנו עשויים להשתמש במידע האישי על מנת לשלוח לכם, בפלטפורמות התקשורת השונות, הודעות ועדכונים, בקשר לשירותי רכבת ישראל ו/או לשימוש שלכם באפליקציה או באתר. אנו רשאים להשתמש במידע האישי שלכם על מנת להשלים פעולות שתבצעו באתר או באפליקציה או כדי ליצור אתכם קשר בנוגע לשירותי האתר והאפליקציה. אם בשלב כלשהו תעדיפו להסיר את עצמכם מרשימת תפוצה, על מנת שלא לקבל הודעות מאתנו, הנכם רשאי לעשות זאת בכל עת.
`}
          <br />
          <b>{`מסירת המידע האישי שלכם:`}</b>
          <br />
          {`
המידע האישי שייאסף עליכם לא יועבר לצדדים שלישיים ללא רשותכם, אלא כפי שמפורט בתנאי מדיניות פרטיות זו.
אנו רשאים ועשויים לשתף מידע אישי עם צדדים שלישיים אמינים המסייעים לנו בתפעול השירותים, לרבות שירותי האתר והאפליקציה (כגון שירותי תחזוקה, אחסון מידע, ניהול בסיס נתונים, ניתוח אתרים, ניו-מדיה ושיפור השירותים), בניתוח האופן בו נעשה שימוש בשירותי האתר והאפליקציה, במסירת הודעות ועדכונים ובאספקת שירותים למשתמשי האתר והאפליקציה.
בנוסף, ייתכן ונידרש למסור מידע אישי על פי חוק, לשם ציות להליכים משפטיים, על מנת להימנע מחבות משפטית שלנו, על מנת להגן על רכוש וזכויות שלנו או של צד שלישי או על בטיחות הציבור או של כל אדם, או כדי למנוע או לעצור פעילות בלתי חוקית, בלתי אתית או כזו החושפת אותנו לסיכון משפטי.

`}
          <br />
          <b>{`שמירת המידע שלכם:`}</b>
          <br />
          {`
רכבת ישראל משקיעה משאבים רבים לצורך אבטחת המידע האישי שברשותנו. על מנת להפחית את הסיכון של גישה או העברה בלתי מאושרת, וכדי לוודא שימוש הולם במידע אישי, אנו נוקטים אמצעים פיזיים, אלקטרוניים ומנהליים מתאימים על מנת לשמור ולהגן על המידע אותו אנו מעבדים. עם זאת, אין אנו יכולים לערוב לכך שצדדים שלישיים בלתי מורשים לא יצליחו לעולם לעקוף את מנגנוני ההגנה האמורים או לעשות שימוש במידע אישי למטרות בלתי הולמות.

`}
          <br />
          <b>{`חומרים של צדדים שלישיים:`}</b>
          <br />
          {`
רכבת ישראל עשויה להציע למשתמשי האתר והאפליקציה קישורים לשירותים, משאבים, תכנים או מידע של צדדים שלישיים ("חומרי צדדים שלישיים"). חומרי הצדדים השלישיים אינם תחת שליטת רכבת ישראל או באחריותה, והשימוש בהם וההסתמכות עליהם היא באחריות המשתמשים בלבד. רכבת ישראל לא תישא בשום חבות או נזק שייגרמו לכם כתוצאה מכניסה, שימוש או הסתמכות על חומרי צדדים שלישיים. לידיעתכם, מדיניות פרטיות זו אינה מכסה את נהלי הפרטיות והגנת המידע הנהוגים על ידי אותם צדדים שלישיים ולכן אנו ממליצים לכם לקרוא את מדיניות הפרטיות של הצד השלישי שאליו תקבלו גישה באמצעות האתר או האפליקציה, בטרם תמסרו להם מידע אישי.

`}
          <br />
          <b>
            {`
מדיניות השימוש בקבצי Cookies וכלים נוספים:
`}
          </b>
          <br />
          {`
רכבת ישראל עושה שימוש מקובל בקבצי cookies ("עוגיות") וכן בכלים ושירותים דומים לצורך ניהול ומתן שירותי האתר והאפליקציה ושיפורם, מעקב אחר התעבורה באתר, מסירת מידע רלוונטי לציבור (שלא למטרות מסחריות), כגון מידע בדבר שירותי רכבת ישראל, שירותי תחבורה ציבורית, תקלות או שיבושים בתנועת הרכבות ומידע רלוונטי נוסף, גם באמצעות פלטפורמות דיגיטליות נוספות, כגון רשתות חברתיות. לשם כך עשויה רכבת ישראל לעשות שימוש גם ב"עוגיות" או בשירותיהם של צדדים שלישיים, כגון גוגל, פייסבוק ואחרים, העשויים לאפשר להם לאסוף פרטי מידע, כגון כתובות ה-IP של המשתמשים, לצורך מתן שירותי האתר והאפליקציה ומסירת המידע האמור לציבור.

"עוגיות" הן קבצי טקסט קטנים הנשלחים אל מכשירכם על ידי אתרי האינטרנט בהם אתם מבקרים. ה"עוגיות" נשמרות במכשירכם והן נשלחות מחדש אל אתרי האינטרנט בעת הכניסה הבאה אליהם.

באתר אנו עושים שימוש הן ב"עוגיות" פונקציונליות קבועות, שהשימוש בהן חיוני להפעלת שירותי האתר הבסיסיים והן ב"עוגיות" פונקציונליות נוספות שהשימוש בהן חיוני להפעלת שירותים נוספים באמצעות האתר.

השימוש ב"עוגיות" פונקציונליות קבועות באתר, וכן השימוש בכלים או שירותים דומים כאמור לעיל באפליקציה, אינם נתונים לבחירתו של המשתמש ועל כן עצם המשך השימוש באתר לאחר הכניסה אליו ועצם הורדת האפליקציה והשימוש בה מהווים הסכמה מראש מצדכם לשימוש ב"עוגיות" הקבועות ובכלים ובשירותים הדומים.

לעומת זאת, השימוש שנעשה באתר ב"עוגיות" פונקציונאליות נוספות וכן בשירותים נוספים העשויים לאסוף פרטי מידע של המשתמשים, כמתואר לעיל, נתון לבחירתכם החופשית. אך אם בחרתם שלא לאשר את השימוש ב"עוגיות" נוספות ובשירותים נוספים אלו, ייתכן שחלק משירותי האתר לא יהיו זמינים. בעת כניסה ראשונה לאתר ניתנת למשתמש האפשרות לבחור להסכים לשימוש ב"עוגיות" פונקציונליות נוספות או לא להסכים לכך, או לגשת ל"הגדרות". תוקף ההסכמה שלכם לשימוש ב"עוגיות" פונקציונאליות נוספות ובשירותים נוספים כאמור הוא לשישה חודשים, אך אתם רשאים לשנות את בחירתכם בכל עת, בעת השימוש בדפדפנים השונים, על פי מדיניות מחיקה וניהול קבצי עוגיות של כל אחד מהם:

מדיניות מחיקה וניהול קבצי עוגיות בדפדפני מייקרוסופט
מדיניות מחיקה וניהול קבצי עוגיות בדפדפן גוגל כרום
מדיניות מחיקה וניהול קבצי עוגיות בדפדפן פיירפוקס

`}
          <br />
          <b>{`שינויים במדיניות הפרטיות:`}</b>
          <br />
          {`
אנו שומרים לעצמנו את הזכות לעדכן מדיניות פרטיות זו מעת לעת. הגרסה העדכנית ביותר של המדיניות היא הגרסה הקובעת לעניין השימוש שנעשה במידע האישי ובמידע הלא אישי שלכם, ולכן באחריות המשתמשים לבקר מעת לעת בעמוד זה על מנת לעיין במדיניות הפרטיות העדכנית שלה הם כפופים.
`}
          <br />
          <b>{`יצירת קשר וזכות לעיין במידע:`}</b>
          <br />
          {`
על מנת לעיין, בהתאם להוראות הדין, במידע האישי שלכם שנמצא ברשותנו, וכן לצורך בירור פרטים אחרים הקשורים למידע שלך שנמצא ברשותנו, הנכם מוזמן ליצור עמנו קשר בכתובת הדוא"ל: Pniyotz@rail.co.il
עדכון אחרון: אוקטובר, 2022.

`}
        </div>
        <h3>{`שינויים באתר והפסקת פעילותו:`}</h3>
        <div>
          {`רכבת ישראל שומרת לעצמה את הזכות, על פי שיקול דעתה הבלעדי, לשנות את הוראות תקנון זה, את תנאי השימוש באתר, את מבנה האתר, תוכנו, מראהו, וכן את היקפם וזמינותם של השירותים המוצעים בו וכל היבט אחר הכרוך בו, ללא מתן התראה מראש. ככלל, תוקפם של שינויים יהא מיום פרסומם באתר, אלא אם נקבע אחרת ע"י הרכבת.
הרכבת שומרת לעצמה את הזכות להפסיק בכל עת, לפי שיקול דעתה הבלעדי, את פעילות האתר ולמשתמש לא תהא כל טענה ו/או תביעה כלפיה. 


`}
        </div>
        <h3>{`מקום שיפוט`}</h3>
        <div>
          {`על תקנון זה יחולו דיני מדינת ישראל בלבד. מקום השיפוט הבלעדי בכל עניין הנוגע לו זה הינו בבית המשפט המוסמך בלוד.

`}
        </div>
      </div>
    </>
  );
};

export default HomeEmptyHe;
