import styles from "./HomeEmpty.module.css";

const HomeEmptyAr: React.FC = (props) => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h1>{` ضوابط الموقع والتطبيق`}</h1>
        <div>
          {`إن استخدام الموقع أو تطبيق قطارات إسرائيل (يشار إليهم فيما بعد بـ "الموقع") والمحتويات المعروضة فيه وبأي وسيلة أخرى يخضع لشروط الاستخدام المحددة والمفصلة أدناه. إن مجرد استخدام الموقع يشير إلى حقوق المستخدم. الموافقة على جميع شروط الاستخدام المفصلة في هذه اللوائح.
         استخدام صيغة المذكر أدناه هو من أجل الراحة فقط.`}
        </div>
        <h3>{"الملكية الفكرية"}</h3>
        <div>
          {`جميع حقوق الملكية الفكرية وحقوق النشر في البيانات و/أو المعلومات التي تظهر على الموقع مملوكة حصريًا لشركة قطارات إسرائيل المحدودة (فيما يلي: "السكك الحديدية").لا يجوز لك نسخ أو إعادة إنتاج أو بيع أو ترجمة أو نشر أي معلومات بأي شكل من الأشكال التي تظهر على الموقع ولا يجوز لك القيام بأي استخدام تجاري بأي تفاصيل على الموقع دون الحصول على إذن كتابي مسبق من السكة الحديد.

سيتم استخدام الموقع ومعلوماته فقط للاحتياجات الشخصية وغير التجارية ووفقًا للقانون.

حقوق الملكية الفكرية على الموقع، بما في ذلك حقوق النشر، المطبقة على المعلومات الموجودة في الموقع ومحتواه والتصميم الجرافيكي للموقع وأي تفاصيل أخرى فيه، وكذلك العلامة التجارية للموقع بما في ذلك شعار القطار وشعار القطار، هي ملك للقطار فقط. ولا يجوز استخدامها ولا يجوز توزيعها أو نسخها أو عرضها علنًا أو إعطاء أي جزء من محتوى الموقع إلى أي طرف ثالث دون موافقة كتابية مسبقة من القطار.

`}
        </div>
        <h3>{`محتوى الموقع وسلامته`}</h3>
        <div>
          {`يتم تقديم المعلومات الموجودة على الموقع لراحة المستخدم فقط. لن تتحمل السكك الحديدية و/أو أي شخص ينوب عنها أي مسؤولية عن أي ضرر أو إزعاج أو حزن وعن أي نتيجة مباشرة و/أو غير مباشرة قد تلحق بالمستخدم أو ممتلكاته أو طرف ثالث بسبب استخدام السكة الحديد. محتويات الموقع أو بسبب الاعتماد عليها. لن تتحمل شركة قطارات إسرائيل و/أو أي شخص ينوب عنها المسؤولية ولن تتحمل أي ضرر غير مباشر ونتائجه وأضرار تلحق بالمستخدم أو بطرف ثالث نتيجة استخدام الموقع و/أو المعلومات الموجودة فيه و/أو الخدمات المقدمة فيه - مهما كان سبب المطالبة.

لن يكون القطار مسؤولاً عن أي خطأ أو خطأ أو إغفال أو عدم دقة و/أو تحديث المعلومات و/أو البيانات المنشورة على الموقع، بما في ذلك التغييرات في أوقات السفر و/أو المسارات، وعدم الالتزام بالجداول و/أو الإلغاء. القطارات، وكذلك أي ضرر و/أو نفقات متكبدة نتيجة لذلك.
نعم، لن تتحمل السكك الحديدية أي مسؤولية عن المحتوى الذي يظهر على المواقع الأخرى التي تؤدي إليها الروابط الموجودة في الموقع، ودون الانتقاص من هذه القاعدة - بما في ذلك المواقع الإلكترونية لبيع تذاكر القطار، ولا تضمن أن أي رابط سيؤدي إلى موقع نشط.

تبذل شركة قطارات إسرائيل قصارى جهدها للحفاظ على التشغيل الطبيعي للموقع. ومع ذلك، لا تضمن شركة قطارات إسرائيل أن الخدمة على الموقع سيتم تقديمها دون انقطاع أو أعطال، بما في ذلك الأعطال في الأجهزة أو البرامج أو خطوط الاتصال. لن تتحمل شركة قطارات إسرائيل، بشكل مباشر و/أو غير مباشر، المسؤولية عن أي ضرر في حالة حدوث مشكلة فنية و/أو انقطاع و/أو خلل في الموقع والخدمة المقدمة عليه و/أو خلل وتعطيل في شبكة الإنترنت العالمية. الإنترنت و/أو خطوط الاتصال المختلفة التي تتيح الوصول إلى الإنترنت.

تبذل شركة قطارات إسرائيل قصارى جهدها للتأكد من أن الموقع آمن للاستخدام وخالي من أي "فيروسات" أو أي برامج ضارة و/أو غير قانونية. ومع ذلك، لا تستطيع شركة السكك الحديدية الإسرائيلية ضمان أن يكون الموقع آمنًا للاستخدام وخاليًا من البرامج الضارة و/أو البرامج غير القانونية في أي وقت. لن تكون شركة قطارات إسرائيل، بشكل مباشر و/أو غير مباشر، مسؤولة عن أي ضرر يلحق بالمستخدم نتيجة "الفيروسات" و/أو البرامج الضارة أو غير القانونية.
`}
        </div>
        <h3>{`المحتوى الإعلاني`}</h3>
        <div>
          {`إن المحتوى الإعلاني، بقدر ما يتم نشره على موقع الويب أو على مواقع الويب التي تؤدي إليها الروابط الموجودة على موقع الويب، هو مسؤولية المعلنين وحدهم. إن استخدام المحتوى الإعلاني والاعتماد عليه يقع على عاتق المستخدم وحده.
`}
        </div>
        <h3>{`الاستخدام المقبول`}</h3>
        <div>
          {`سيكون استخدام الموقع و/أو التطبيق استخدامًا معقولًا ومقبولًا، للأغراض القانونية، وفقًا للشروط والتعليمات الواردة في هذه اللائحة ومع مراعاة الأحكام القانونية ذات الصلة.
يُمنع منعاً باتاً القيام بأي أعمال من شأنها أن تسبب حملاً كبيراً وغير معقول على الموقع أو البنية التحتية، أو الإضرار بالكود البرمجي أو رؤية الموقع أو الوصول إليه بوسائل غير قانونية.
`}
        </div>
        <h3>{`خدمة تلقي التحديثات حول التغييرات في التطبيق`}</h3>
        <div>
          {`مثل أي خدمة لإرسال المعلومات، قد تتأثر خدمة "أخطرني بالتغييرات" أيضًا بالاتصالات وأعطال أخرى. لذلك، قطارات إسرائيل ليست مسؤولة عن أي ضرر أو نفقات يتكبدها مستخدمو الخدمة، أو أي شخص آخر بسبب اعتمادهم على التحديثات التي لم يتم استلامها على الإطلاق، والتي تم استلامها بطريقة غير صحيحة أو مشوهة، والتي تحتوي على معلومات لم تكن دقيقة أو حديثة وقت استلامها أو وقت توزيعها والتحديثات التي لم يتم توزيعها على الإطلاق، أو التي تم توزيعها بشكل غير صحيح لأي سبب من الأسباب.
`}
        </div>
        <h3>{`شروط السفر بالقطار`}</h3>
        <div>
          {`يخضع نقل الركاب وأمتعتهم في القطارات للقواعد المنصوص عليها في الأحكام القانونية ذات الصلة، بما في ذلك قانون السكك الحديدية [النسخة الجديدة] لعام 1972 والأنظمة المقررة بموجبه، وكذلك لتعليمات وزارة النقل شروط النقل والسفر الإضافية التي تبلغها السكك الحديدية للجمهور من وقت لآخر، من خلال وسائل مختلفة، بما في ذلك هذا الموقع.
    `}
        </div>
        <h3>{`شراء تذاكر السفر`}</h3>
        <div>
          {`من الممكن شراء تذاكر السفر من خلال مواقع أخرى تديرها كيانات تجارية وغيرها، ولا تديرها شركة قطارات إسرائيل. قطارات إسرائيل ليست مسؤولة عن المعلومات الموجودة في هذه المواقع أو شروط شراء التذاكر عليها، حتى لو ظهرت على هذا الموقع روابط تؤدي إلى هذه المواقع.

شروط استخدام بطاقة Rab-Co ليست من مسؤولية شركة قطارات إسرائيل وتظهر على موقع Rab-Co الإلكتروني أو مواقع Hopon الإلكترونية
`}
        </div>
        <h3>{`إجراء عمليات الدفع من خلال الموقع الإلكتروني`}</h3>
        <div>
          {`سيتم الدفع من خلال موقع مضاعفة رسوم السفر حيث يتم تحصيل رسوم من الراكب، من خلال تقديم التفاصيل الشخصية (المشار إليها فيما يلي باسم "تفاصيل التعريف") للمستخدم كما هو مطلوب في صفحة الدفع بالإضافة إلى تفاصيل بطاقة ائتمان صالحة تشرف عليها إسرائيل السكك الحديدية، حسب تقديرها الخاص.
سيتم خصم قيمة الدفع من بطاقة الائتمان فورًا في نهاية عملية الدفع على الموقع الإلكتروني وسيتم ذلك بعملية شحن واحدة.
أحد شروط إتمام عملية الدفع هو أن يتم استلام التفاصيل التي أدخلها المستخدم واستيعابها على الموقع بالكامل، كما هو مطلوب في الموقع.
قد تتعدد الأسباب التي قد تسبب أعطالاً وتعطيلاً في استلام عملية الدفع ويقر المستخدم بأنه على علم باحتمالية حدوث مثل هذه الأعطال في حالته، دون الانتقاص من حقه في إلغاء الدفع في حال لعدم إتمام عملية الدفع.
ولن يكتمل تنفيذ الدفع ويعتبر صحيحاً إلا بعد تلقي تأكيد إتمام عملية الدفع.
إذا وصلت للمستخدم رسالة أثناء أو بعد إجراء الدفع، بخصوص خطأ في تفاصيل الدفع أو بخصوص عطل حدث في النظام عند استلام عملية الدفع أو إذا لم يتلق تأكيداً بإتمام عملية الدفع، فإن يجب على المستخدم الاتصال بمركز خدمة العملاء في قطارات إسرائيل عبر الهاتف على الرقم 5770* حسب ساعات عمل المركز وتنظيم إتمام عملية الدفع.
إن كتابة تفاصيل هوية ليست خاصة بالمستخدم و/أو ليس بموجب ترخيص قانوني لاستخدام تفاصيل هوية شخص آخر - محظور تمامًا بموجب القانون.
`}
        </div>
        <h3>{`خدمة حجز المقاعد`}</h3>
        <div>{`الخدمة غير نشطة حتى إشعار آخر.


`}</div>
        <h3>{`تجريبي "القيمة المتراكمة المباشرة"`}</h3>
        <div>
          {`وفقًا لتعليمات وزارة المواصلات، تعمل شركة قطارات إسرائيل على تشغيل خدمة "القيمة المتراكمة المباشرة"، والتي سيتم اختبارها لفترة تجريبية، بدءًا من 4 مارس 2021.

تتيح خدمة "القيمة التراكمية المباشرة" لركاب الخطوط المتعددة، الذين طالبوا بالقيمة التراكمية، السفر بالقطار باستخدام القيمة التراكمية، وذلك من خلال المرور مباشرة عبر بوابات الدخول لمحطة القطار (المدققين) وإجراء التحقق، دون شراء مسبق من تذكرة السفر ودون الحاجة إلى إجراء عملية التحقق المبكر.

ويتم استخدام خدمة "القيمة المجمعة المباشرة" من خلال التحقق من صحة الدخول عند بوابة الدخول (المدقق) في محطة المغادرة، باستخدام البطاقة المتعددة الخطوط، قبل الرحلة، والتحقق من الخروج عند بوابة الخروج، في الوجهة المحطة في نهاية الرحلة.

ويشترط استخدام خدمة "القيمة التراكمية المباشرة" وجود رصيد نقدي في القيمة التراكمية للبطاقة المتعددة الخطوط، وهو ما يكفي لدفع أجرة الرحلة كاملة. وتقع على عاتق الراكب مسؤولية التأكد من وجود رصيد مالي كافٍ في القيمة المتراكمة.

يرجى ملاحظة: السفر بدون رصيد مالي كافٍ هو نفس السفر بالقطار بدون تذكرة مناسبة!
  
إن أي استخدام لخدمة "القيمة المجمعة المباشرة"، لأي رحلة، مشروط بوجود رصيد أدنى قدره 30 شيكل في القيمة المجمعة.
  
يُسمح باستخدام خدمة "القيمة المجمعة المباشرة" لراكب واحد فقط.

وتم اختبار خدمة "القيمة التراكمية المباشرة" خلال الفترة التجريبية بالإضافة إلى الخدمة الاعتيادية.
يستمر الاستخدام العادي للقيمة المتراكمة دون تغيير، بما في ذلك خيار تحويل تذكرة واحدة، أو عدة تذاكر سفر، من القيمة المتراكمة وإمكانية إجراء التحقق المبكر، قبل التحقق والمرور عبر بوابة الدخول.

يرجى ملاحظة: خدمة "القيمة المتراكمة المباشرة" ليست مخصصة للمسافرين الذين يحملون القيمة المتراكمة وبطاقة سفر دورية على بطاقتهم متعددة الخطوط.

بحسب تعليمات وزارة المواصلات، وكما هو الحال في السفر بالحافلة، فإن استخدام خط متعدد له قيمة مجمعة وبطاقة سفر دورية، يؤدي إلى تفعيل بطاقة السفر الدوري بشكل افتراضي. إذا كانت بطاقتك المتعددة الخطوط تحتوي على قيمة متراكمة وبطاقة سفر دورية وترغب في استخدام القيمة المتراكمة - توجه إلى ماكينات البيع الآلي وقم بتفعيل القيمة المتراكمة بشكل طبيعي وليس من خلال خدمة "القيمة المتراكمة المباشرة".
`}
        </div>
        <h3>{`استخدام تطبيقات الدفع`}</h3>
        <div>
          {`استخدام تطبيقات الدفع يكون وفقاً لتعليمات وزارة المواصلات وشروط استخدام التطبيق التي من خلالها اختار الراكب دفع الأجرة وإجراء التحقق عند بوابات الدخول والخروج في محطات القطار. شركة قطارات إسرائيل المحدودة ليست مسؤولة بأي حال من الأحوال عن صحة الطلبات، أو تحصيل الأسعار من خلالها، أو مستوى الأسعار عند استخدامها، أو فرض رسوم مالية متزايدة من خلالها، أو أي جانب آخر من جوانب استخدامها. للمزيد للحصول على المعلومات، يرجى الاتصال بموقع الهيئة الوطنية للمواصلات العامة بوزارة المواصلات

وفقا لأحكام القانون ووزارة المواصلات، يخضع السفر باستخدام تطبيق الدفع للتصديق على بوابات الدخول والخروج في محطات القطار، أسوة بالتصديق على البطاقة المتعددة الخطوط. ويتم التحقق من الصحة عن طريق مسح الباركود الذي تم إنشاؤه بواسطة التطبيق وعرضه على شاشة الهاتف الذكي، عند بوابات الدخول والخروج في محطات القطار. يؤدي مسح الباركود ضوئيًا إلى فتح بوابة الدخول أو الخروج في محطة القطار بشكل صحيح ويسمح فقط للراكب بالدخول إلى المحطة أو الخروج منها، حسب الحالة.

السفر بالقطار دون التحقق الصحيح، سواء عند مدخل المحطة الأصلية أو عند الخروج من محطة الوجهة، هو نفس السفر بدون تذكرة وقد يؤدي إلى فرض رسوم مالية متزايدة على الراكب، من خلال التطبيق، وفقا لأحكام قانون وزارة المواصلات.

يجب على المسافر، بناء على طلب مسؤول السكة الحديدية، أن يقدم شاشة التطبيق، حيث يتم عرض الباركود المخصص للرحلة المعينة والذي يجب التحقق من صحته، كما ذكرنا.

في حال وجود أي عطل أو شكوى بخصوص استخدام تطبيقات الدفع، يجب على الراكب الاتصال بمركز خدمة الهيئة الوطنية للمواصلات العامة بوزارة المواصلات على الهاتف: 8787* أو مركز خدمة التطبيق مستخدم.

لا تتصل بمركز خدمة قطار إسرائيل في هذه الأمور، لأن هذا المركز لا يمكنه المساعدة في هذه الأمور.

يحق للراكب الذي قام برحلة باستخدام تطبيق الدفع الحصول على تعويض عن تأخير القطار، وفقا لنفس الشروط التي تنطبق على الراكب الذي قام برحلته باستخدام بطاقة ورقية أو بطاقة متعددة الخطوط، والمفصلة في هذا الموقع على صفحة التعويض عن تأخير/إلغاء القطار
سيتم منح بطاقة تعويض للراكب الذي يحق له الحصول على تعويض من خلال بطاقة ورقية، رسالة نصية قصيرة أو وسيلة رقمية أخرى تحددها شركة قطارات إسرائيل.
`}
        </div>
        <h3>{`السرية والخصوصية`}</h3>
        <div>
          {`تتخذ شركة قطارات إسرائيل الإجراءات اللازمة لمنع التطفل على الموقع، وللحفاظ على سرية البيانات الشخصية للمستخدمين ولمنع انتهاك محتمل لخصوصية المستخدمين. ومع ذلك، لا يمكن استبعاد الاضطرابات تمامًا ولا يمكن تقديم ضمان كامل بالحفاظ دائمًا على السرية والخصوصية. لذلك، يصرح المستخدم بموجب هذا أنه يفهم محتوى هذا القسم وأنه لن يكون لدى المستخدم أي مطالبة و/أو مطالبة و/أو مطالبة ضد شركة قطارات إسرائيل و/أو أي شخص نيابة عنها بسبب إصابة و/أو عطل و/أو أو أعطال مما قد يؤدي إلى الإضرار بخصوصية وسرية معلومات المستخدم كما يقدمها له.

إن توفير رقم هاتف محمول عند إجراء العمليات على الموقع يشكل موافقة على تلقي رسائل الخدمة من شركة قطارات إسرائيل، من خلال الرسائل النصية التي سيتم إرسالها إلى رقم الهاتف المقدم.

`}
        </div>
        <h3>{`تغييرات في الموقع وتوقف نشاطه`}</h3>
        <div>
          {`تحتفظ شركة قطارات إسرائيل بالحق، وفقًا لتقديرها الخاص، في تغيير أحكام هذه الأنظمة، وشروط استخدام الموقع، وبنية الموقع، ومحتواه، ومظهره، وكذلك نطاق وتوافر الخدمات المقدمة فيها وأي جانب آخر معني، دون إعطاء إشعار مسبق. كقاعدة عامة، ستكون صلاحية التغييرات من تاريخ نشرها على الموقع الإلكتروني، ما لم تقرر السكك الحديدية خلاف ذلك.
تحتفظ السكة الحديد بالحق في إيقاف نشاط الموقع في أي وقت، وفقًا لتقديرها الخاص، ولا يجوز للمستخدم أي مطالبة و/أو مطالبة ضده.

`}
        </div>
        <h3>{`مكان للحكم`}</h3>
        <div>
          {`سيتم تطبيق قوانين دولة إسرائيل على هذه اللوائح فقط. ويكون الاختصاص الحصري في جميع المسائل المتعلقة بهذا الأمر هو المحكمة المختصة في اللد.
`}
        </div>
      </div>
    </>
  );
};

export default HomeEmptyAr;
