import styles from "./HomeEmpty.module.css";

const HomeEmptyEn: React.FC = (props) => {
  return (
    <>
      <div className={`${styles.container}`}>
        <h1>{` The regulations of the website and the application`}</h1>
        <div>
          {`The use of the website or the Israel Railways application (hereafter together - the "website") and the content presented therein and by any other means is subject to the terms of use defined and detailed below. The mere use of the website indicates the user's agreement to all the terms of use detailed in these regulations.
         The use of the masculine form below is for convenience only.`}
        </div>
        <h3>{"Intellectual Property"}</h3>
        <div>
          {`All intellectual property rights and copyrights in the data and/or information appearing on the website belong exclusively to Israel Railways Ltd. (hereinafter: "The Railway"). You may not copy, reproduce, sell, translate or publish in any way any information appearing on the website and you may not make any use commercial in any detail on the website without obtaining the railway's written permission in advance.

The use of the website and its information will be done only for personal and non-commercial needs and in accordance with the law.

The intellectual property rights on the site, including copyrights, applicable to the information on the site, its content, the graphic design of the site and any other detail therein, as well as the site's trademark, including the train's slogan and the train's logo, are the property of the train only. They must not be used and they must not be distributed, copied, or presented publicly or give any part of the site's content to any third party without the train's prior written consent.


`}
        </div>
        <h3>{`The content of the site and its integrity`}</h3>
        <div>
          {`The information on the website is presented for the user's convenience only. The railway and/or anyone on its behalf will not bear any responsibility for any damage, inconvenience or grief and for any direct and/or indirect result that will be caused to the user, his property or a third party due to the use of the contents on the website or due to the reliance on them. Israel Railways and/or anyone on its behalf will not be responsible and will not bear any indirect damage, its results and damages caused to the user or a third party, as a result of using the website and/or the information therein and/or the services provided therein - whatever the cause of the claim may be.

The train will not be responsible for any mistake, error, omission or inaccuracy and/or update of the information and/or data published on the website, including changes in travel times and/or routes, non-compliance with schedules and/or cancellation of trains, as well as for any damage and / or expense incurred as a result.
Yes, the railway will not bear any responsibility for the content appearing on other websites to which links on the website lead, and without detracting from this rule - including websites for the sale of train tickets, and does not guarantee that any link will lead to an active website.

The Israel Railways makes every effort to maintain the normal operation of the site. However, Israel Railways does not guarantee that the service on the site will be provided without interruptions and malfunctions, including malfunctions in hardware, software, or communication lines. Israel Railways will not bear, directly and/or indirectly, responsibility for any damage in the event of a technical problem, and/or outages and/or malfunctions on the website and the service provided therein and/or malfunctions and disruptions in the global Internet and/or the various communication lines that allow access to the Internet.

Israel Railways makes every effort to ensure that the website is safe to use and free of any 'virus' or any malicious and/or illegal software. However, Israel Railways cannot guarantee that the website will be safe to use and free of malware and/or illegal software at any time. Israel Railways will not be, directly and/or indirectly, responsible for any damage caused to the user as a result of 'viruses' and/or malicious or illegal software.

`}
        </div>
        <h3>{`Advertising content`}</h3>
        <div>
          {`Advertising content, insofar as it is published on the website or on websites to which links on the website lead, is the sole responsibility of the advertisers. The use of the advertising content and reliance on it are the sole responsibility of the user.

 

`}
        </div>
        <h3>{`Acceptable use`}</h3>
        <div>
          {`The use of the website and/or the application will be reasonable and acceptable use, for legal purposes, according to the terms and instructions in these regulations and subject to the relevant legal provisions.
It is absolutely forbidden to carry out actions that may cause a large and unreasonable load on the site or the infrastructure, damage the software code or the visibility of the site or access it by illegal means.

`}
        </div>
        <h3>{`Service for receiving updates on changes to the application`}</h3>
        <div>
          {`Like any service for sending information, the "Notify me of changes" service may also be affected by communication and other malfunctions. Therefore, Israel Railways is not responsible for any damage or expense incurred by the users of the service, or any other person due to their reliance on updates that were not received at all, that were received in an incorrect or distorted manner, that contained information that was not accurate or current at the time of their receipt or at the time of their distribution and updates that were not distributed at all, or that were distributed improperly, for any reason.

`}
        </div>
        <h3>{`Train travel conditions`}</h3>
        <div>
          {`The transportation of passengers and their luggage on trains is subject to the rules set forth in the relevant legal provisions, including the Railways Ordinance [new version], 1972 and the regulations established pursuant to it, as well as to the instructions of the Ministry of Transportation and additional travel conditions that the railway informs the public from time to time, through various means, including this website.
    
    `}
        </div>
        <h3>{`Purchase of travel tickets`}</h3>
        <div>
          {`Tickets can be purchased through other websites operated by commercial and other entities, and not operated by Israel Railways. Israel Railways is not responsible for the information on these websites or the conditions for purchasing tickets on them, even if connections leading to these websites appear on this website.

The terms of use of the Rab-Co card are not the responsibility of Israel Railways and appear on the Rab-Co online or Hopon websites

`}
        </div>
        <h3>{`Making payments through the website`}</h3>
        <div>
          {`Payment through the site of Doubling Travel Fees where a passenger has been charged, will be made by providing personal details (hereinafter "identification details") of the user as required on the payment page as well as details of a valid credit card honored by Israel Railways, at its sole discretion.
The credit card will be charged for the payment immediately at the end of the payment process on the website and will be done in one charge.
A condition for completing the payment process is that the details entered by the user have been received and absorbed on the website in full, as required on the website.
There may be various reasons that may cause malfunctions and disruptions in the reception of the payment process and the user declares that he is aware of the possibility that such malfunctions will occur in his case, without detracting from his right to cancel the payment in the event of failure to complete the payment process.
The execution of the payment will be completed and considered correct only after receiving confirmation of the completion of the payment process.
If the user received a message during or after making the payment, regarding an error in the payment details or regarding a malfunction that occurred in the system when receiving the payment process or if he did not receive confirmation of the completion of the payment process, the user must contact the Israel Railways customer service center by phone at 5770* according to the center's operating hours , and regulate the completion of the payment process.
Typing identification details that are not the user's and/or not by virtue of legal authorization to use someone else's identification details - is completely prohibited by law.

`}
        </div>
        <h3>{`Reserved seat service`}</h3>
        <div>{`The service is inactive until further notice.


`}</div>
        <h3>{`Pilot "Direct Accumulated Value"`}</h3>
        <div>
          {`According to the instructions of the Ministry of Transport, Israel Railways is operating a "direct accumulated value" service, which will be tested for a pilot period, starting on March 4, 2021.

The "direct accumulated value" service allows multi-line passengers, who have claimed accumulated value, to travel by train using the accumulated value, by passing directly through the entrance gates of the train station (the validators) and performing validation, without prior purchase of the travel ticket and without the need to perform an operation Early validation.

The use of the "direct accumulated value" service is done by validating entry at the entrance gate (the validator) at the departure station, using the multi-line card, before the trip, and validating exit at the exit gate, at the destination station, at the end of the trip.

The use of the "direct accumulated value" service is conditional on a monetary balance in the accumulated value of the multi-line card, which is sufficient to pay the full fare for the trip. It is the passenger's responsibility to ensure that he has a sufficient financial balance in the accumulated value.

Please note: traveling without a sufficient financial balance is the same as traveling by train without a suitable ticket!
  
Any use of the "direct accumulated value" service, for any trip, is conditional on a minimum balance of NIS 30 in the accumulated value.
  
The use of the "direct accumulated value" service is allowed for only one passenger.

The "direct accumulated value" service was tested during the pilot period, in addition to the regular service.
The normal use of accumulated value continues without change, including the option to convert one ticket, or several travel tickets, from the accumulated value and the possibility to perform early validation, before validating and passing through the entrance gate.

Please note: the "direct accumulated value" service is not intended for passengers who hold both accumulated value and a periodic travel card, on their multi-line card.

According to the instructions of the Ministry of Transportation, and as with traveling by bus, using a multi-line that has both an accumulated value and a periodic travel card, activates the periodic travel card by default. If your multi-line card has both an accumulated value and a periodic travel card and you wish to use the accumulated value - go to the vending machines, activate the accumulated value normally and not through the "direct accumulated value" service.
`}
        </div>
        <h3>{`Using payment apps `}</h3>
        <div>
          {`The use of payment applications is in accordance with the instructions of the Ministry of Transportation and the terms of use of the application through which the passenger chose to pay the fare and to perform the validation at the entrance and exit gates at the train stations. Israel Railways Ltd. is in no way responsible for the correctness of the applications, the collection of fares through them, the level of fares when using them, the imposition of increased financial charges through them, or any other aspect of their use. For more information, please contact the National Authority for Public Transportation website at the Ministry of Transportation

According to the provisions of the law and the Ministry of Transportation, travel using a payment application is subject to validation at the entrance and exit gates at the train stations, similar to the validation of the multi-line card. The validation is done by scanning a barcode generated by the application and displayed on the smartphone screen, at the entrance and exit gates at the train stations. Scanning a barcode properly opens the entrance or exit gate at the train station and only it allows the passenger to enter or exit the station, as the case may be.

Traveling by train without proper validation, both at the entrance to the origin station and at the exit from the destination station, is the same as traveling without a ticket and may result in the imposition of increased financial charges on the passenger, through the application, in accordance with the provisions of the law and the Ministry of Transportation.

A passenger must present, at the request of a railway official, the application screen, where the barcode intended for the particular trip is displayed and which must be validated, as mentioned.

In the event of any malfunction or complaint regarding the use of the payment applications, the passenger must contact the service center of the National Authority for Public Transportation, at the Ministry of Transportation, by phone: *8787, or the service center of the application used.

Do not contact the Israel Railways service center in these matters, because this center cannot help with these matters.

A passenger who made a trip using a payment application is entitled to compensation for a train delay, according to the same conditions that apply to a passenger who made his trip using a paper card or multi-line card, which are detailed on this website on the compensation for train delay/cancellation page
A compensation card will be given to the passenger entitled to compensation through a paper card, an SMS message or another digital means determined by Israel Railways.

`}
        </div>
        <h3>{`Confidentiality and Privacy`}</h3>
        <div>
          <b>{`General`}</b>
          <br />
          {`Israel Railways Ltd. ("Israel Railways", "we" or "us") is committed to protecting the privacy of the users of the site and application ("user/s" or "you"). The Israel Railways Website ("the Website") and the Israel Railways application ("App") allow users to receive information about Israel Railways' services (“the Services"), book trips, apply for a position and inquire with Israel Railways on various issues using online application forms ("Website and App Services").

This Privacy Policy (“the Privacy Policy") was created to inform you about the way we collect, store and use the information you provide to us through the use of the Website and App Services, using any computer or other electronic device, and it describes the measures we take to protect the information collected by us. The mere use of the Website and downloading and/or using our application constitute your consent to our privacy policy.`}
          <br />
          <br />
          <b>
            {`

Details on the collection and use of the information
`}
          </b>
          <br />
          {`When using our Website and App, we may collect the following types of information:
"Personal information" - information that can be used to contact you or identify you. Some of the information may identify you personally, such as your name, address, your e-mail address, your phone number, your ID number, etc.
"Non-personal information" - information that in itself does not allow us to identify you, such as statistical information about the pages on the Website and App you viewed, the services of the Website and App that interested you and more.
You are not obligated to provide us with the personal information described below, but for the purpose of using the Website and App Services in the best way, you may be required to share with Israel Railways the specified personal information, otherwise, you may use the Website and App services only partially.
  `}
          <br />
          <br />
          <b>
            {`

How do we collect your information?
`}
          </b>
          <br />
          {`
We collect personal information and non-personal information, inter alia, using the following methods:
`}
          <br />
          <br />
          <b>{`Contact form:`}</b>
          <br />
          {`You can contact us using the online application form, and we will collect your name, residential address, e-mail address, telephone number and of course the content of your application.
 `}
          <br />
          <b>{`Locating an item lost on the train`}</b>
          <br />
          {`
 You can contact us regarding the loss at the station or on the train, and we will collect your name, telephone number, date and time of the loss and the content of your application.
  `}
          <br />
          <b>{`Request for compensation for train delay / cancellation:`}</b>
          <br />
          {`You can contact us regarding compensation for delay / cancellation of a train, and we will collect your name, residential address, e-mail address, telephone number, travel details and the content of your inquiry.`}
          <br />
          <b>{`Request for shooting video inside Israel Railways complexes`}</b>
          <br />
          {`ou can submit an application for shotting videos in Israel Railways complexes, and we will collect your name, ID number, telephone number, e-mail address, and details about the requested footage.
  `}
          <br />
          <b>{`Receiving information from Israel Railways:`}</b>
          <br />
          {`Your e-mail address and / or telephone number may be used to send you updates on Israel Railways services.
 `}
          <br />
          <b>{`Applying for an open position`}</b>
          <br />
          {`You can apply for a position with the Israel Railways through the Website. To do this, you will be required to register in the system through which we will collect your name, ID number, telephone number, e-mail address, user name and password.
`}
          <br />
          <b>{`Additional information:`}</b>
          <br />
          {`For the purpose of providing services and assistance with inquiries and questions from users of the Website and App, we may ask you for additional information.
`}
          <br />
          <b>
            {`
How do we use your information?

  `}
          </b>
          <br />
          {`
We use the information collected from you as follows:

`}
          <br />
          <b>{`Providing the Services: `}</b>
          <br />
          {`We may use personal information and non-personal information in order to provide the Website and App Services and to manage, provide, support, solve technical problems, protect, improve and ensure the proper operation of the Website and App Services, as well as for the purposes of contacting you.
 `}
          <br />
          <b>{`Sending messages and contacting:`}</b>
          <br />
          {` Subject to the provisions of the law, we may use the personal information to send you, in the various communication platforms, messages and updates, in connection with Israel Railways services and / or your use of the App or Website. We may use your personal information to complete actions you perform on the Website or App or to contact you regarding the Website and App Services. If at any point you prefer to remove yourself from a mailing list in order not to receive messages from us, you may do so at any time.`}
          <br />
          <b>{`Providing your personal information:`}</b>
          <br />
          {`The personal information collected about you will not be transferred to third parties without your permission, except as specified in the terms of this privacy policy.
We are allowed and may share personal information with trusted third parties who assist us in operating the Services, including the Website and Application Services (such as maintenance services, information storage, database management, website analysis, new media and improving the Services), in analyzing how the Website and Application Services are used, in delivering notices and updates, and in providing services to users of the Website and App.
In addition, we may be required to provide personal information by law, in order to comply with legal proceedings, avoid our legal liability, protect our property and rights of either a third party or the safety of the public or of any person, or to prevent or stop activity that is illegal, unethical or one that exposes us to legal risk.
`}
          <br />
          <b>{`Saving your information:`}</b>
          <br />
          {`Israel Railways invests many resources to secure our personal information. We take appropriate physical, electronic and administrative measures to maintain and protect the information we process, in order to reduce the risk of unauthorized access or transfer and to ensure the appropriate use of personal information. However, we cannot guarantee that unauthorized third parties will never be able to circumvent the said defense mechanisms or use personal information for inappropriate purposes.
`}
          <br />
          <b>{`Third-Party Materials:`}</b>
          <br />
          {`Israel Railways may offer users of the Website and App links to services, resources, content or information of third parties ("Third-Party Materials"). The Third-Party Materials are not under the control or responsibility of the Israel Railways, and their use and the reliance on them is the sole responsibility of the users. Israel Railways will not bear any liability or damage caused to you as a result of entering, using or relying on third-party materials. Please note that this privacy policy does not cover the privacy practices and information protection practices employed by such third parties and therefore we recommend that you read the privacy policies of the third parties that you access through the Website or App before providing them with personal information.
`}
          <br />
          <b>
            {`
Cookie use policy and additional tools:
`}
          </b>
          <br />
          {`Israel Railways uses cookies ("Cookies") and similar tools and services for the purpose of managing and providing and improving the Website and App Services, tracking Website traffic, providing relevant information to the public (non-commercial purposes), such as information about Israel Railways services, public transportation services, faults or disruptions in train traffic and other relevant information, also through other digital platforms, such as social networks. To this end, Israel Railways may also use “Cookies” or the services of third parties, such as Google, Facebook and others, which may allow them to collect information, such as users' IP addresses, for the purpose of providing the Website and App Services and providing the aforementioned information to the public.
"Cookies" are small text files that are sent to your device by the websites you visit. The “Cookies” are stored on your device and resent to websites the next time you log in.

On the website, we use both permanent functional "Cookies", the use of which is essential for the activation of the basic website services, and additional functional "Cookies", the use of which is essential for the activation of additional services through the Website.

The use of permanent functional "Cookies" on the website, as well as the use of similar tools or services in the application as mentioned above, are not subject to the user's choice and therefore the mere continuation of using the Website after entering it and the mere fact of downloading the application and using it constitute your prior consent to the use of the permanent "Cookies" and the similar tools and services.

On the other hand, the use made on the Website of additional functional "Cookies" as well as additional services that may collect user information, as described above, is subject to your free choice. However, if you chose not to approve the use of additional "Cookies" and such additional services, some of the Website's services may not be available. When entering the Website for the first time, the user is given the option to choose to agree to or decline the use of additional functional "Cookies", or to access "Settings". The validity of your consent to the use of additional functional "Cookies" and additional services as mentioned is for six months, however, you may change your choice at any time while using the various browsers, according to the policy of deleting and managing cookie files of each of them:

Delete and manage cookies policy for Microsoft browsers
Delete and manage cookies policy for Google Chrome browser
Delete and manage cookies policy for Firefox browser


`}
          <br />
          <b>{`Changes to the Privacy Policy:`}</b>
          <br />
          {`We reserve the right to update this Privacy Policy from time to time. The most recent version of the policy is the effective version regarding the use made of your personal and non-personal information, so it is the responsibility of users to visit this page from time to time in order to review the latest privacy policy to which they are subject.`}
          <br />
          <b>{`Contacting us and the Right to Review Information:`}</b>
          <br />
          {`In order to review your personal information that is in our possession in accordance with the provisions of the law, and for the purpose of clarifying other details related to your information that is in our possession, you are welcome to contact us at the email address: Pniyotz@rail.co.il

`}
        </div>
        <h3>{`Changes to the website and termination of its activity`}</h3>
        <div>
          {`Israel Railways reserves the right, at its sole discretion, to change the provisions of these Regulations, the terms of use of the website, the structure of the website, its content, its appearance, as well as the scope and availability of the services offered therein and any other aspect involved, without giving prior notice . As a rule, the validity of changes will be from the date of their publication on the website, unless otherwise determined by the railway.
The railway reserves the right to stop at any time, at its sole discretion, the activity of the site and the user shall not have any claim and/or claim against it. 


`}
        </div>
        <h3>{`Place of Judgment`}</h3>
        <div>
          {`The laws of the State of Israel shall apply to these regulations only. The exclusive place of jurisdiction in all matters concerning this is in the competent court in Lod.

`}
        </div>
      </div>
    </>
  );
};

export default HomeEmptyEn;
